import React from "react";
import {
  TextField as MuiTextField,
  Typography,
  TextFieldProps,
} from "@mui/material";

interface GenericTextFieldProps extends Omit<TextFieldProps, "variant"> {
  name: string;
  label: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
}

const TextField: React.FC<GenericTextFieldProps> = ({
  name,
  label,
  error = false,
  helperText = "",
  required = false,
  value,
  onChange,
  ...rest
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        width: "100%",
      }}
    >
      <Typography
        variant="body2"
        fontSize="clamp(12px, 0.8vw, 16px)"
        color="text.secondary"
        textAlign={"left"}
        sx={{ marginLeft: "4px" }}
      >
        {label}
        {required && <span style={{ color: "red", marginLeft: "2px" }}>*</span>}
      </Typography>
      <MuiTextField
        name={name}
        fullWidth
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
        sx={{
          backgroundColor: "white",
          border: "1px solid #AEBECD",
          fontSize: "clamp(15px, 0.8vw, 24px)",
          borderRadius: "8px",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:focus .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          padding: 0,
        }}
        {...rest}
      />
    </div>
  );
};

export default TextField;
