import { FormattedMessage, useIntl } from "react-intl";
import { ErrorResponse } from "@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes";
import { SerializedError } from "@reduxjs/toolkit";
import MediaQuery from "react-responsive";
import { forwardRef, useCallback, useRef, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
//import { enqueueSnackbar } from "notistack";
//import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import { removeNils } from "shared-utils";
import { deactivateBins } from "../../state/BinsActiveSlice";
import useAudioProcessing, { BinSet } from "../../hooks/useAudioProcessing";
import { type RootState } from "../../state/reducers";
import useCurrentClinicId from "../../hooks/useCurrentClinic";
import { TranscriptionSection } from "../../hooks/useTranscriptionState";
import useScrollWithOutput from "../../hooks/useScrollWithOutputHook";
import SehatSpinner from "../../components/SehatSpinner";
import useActiveVisit from "../../hooks/useActiveVisit";
import RecordingButtonRow from "../../components/RecordingButtonRow";
import useCurrentHealthOrg from "../../hooks/useCurrentHealthOrg";
import DiagnosisPanel from "../../components/DiagnosisPanel";
import {
  InitiateVisitInput,
  InitiateVisitMutation,
  useInitiateVisitMutation,
  useUpdateVisitAssessmentMutation,
  useUpdateVisitNotesMutation,
  PatientQuery,
  Vitals,
  usePatientMedicationQuery,
  usePatientQuery,
  useVitalsQuery,
  useVisitByIdQuery,
} from "../../graphql/generated";
import { textChangeHandler } from "../../utils/eventUtils";
import { useServiceBag } from "../../services/ServiceBag";
import { setVisit } from "../../state/visitSlice";
import BinCardStack from "../../components/BinCardStack";
import { updateNotes } from "../../state/assessmentSlice";
import { AssessmentInput } from "../../gql/graphql";
import PainPointPairView from "../../components/PainPointPairView";
import useMemoizedRemoveNils from "../../hooks/useMemoizedRemoveNils";
import AmchurCard from "../../components/AmchurCard";
import TranscriptionPanel, {
  type TranscriptionPanelProps,
} from "./TranscriptionPanel";
import VitalsBar from "./VitalsBar";
import { MedicationInfo } from "./VisitInteractionPanel";

interface InitiateVisitResult {
  data?: InitiateVisitMutation;
  error?: ErrorResponse | SerializedError;
}

interface PhysicalsPanelProps {
  vitals: Vitals;
}

function PhysicalsPanel({ vitals }: PhysicalsPanelProps) {
  const frontPainPoints = removeNils(vitals.frontPainPoints);
  const backPainPoints = removeNils(vitals.backPainPoints);
  return (
    <Box mx="auto" display="flex" width="100%" justifyContent="center">
      <PainPointPairView
        scale={0.3}
        frontPainPoints={frontPainPoints}
        frontToggleSelection={() => {}}
        backPainPoints={backPainPoints}
        backToggleSelection={() => {}}
      />
    </Box>
  );
}

// simplified version of VisitInProgress for earlier release
// no vitals, or medication info, etc. -- just transcription and notes.

function SimpleVisitInProgressRoute() {
  const { envService } = useServiceBag();
  const clinicId = useCurrentClinicId();
  const recordingOnly = useCurrentHealthOrg()?.trainingOnly || false;
  const [initiateVisit] = useInitiateVisitMutation();
  const scrollRef = useRef<HTMLUListElement>(null);
  //const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector(
    (state: RootState) => state.currentUser.currentUser?.email
  );

  useScrollWithOutput(scrollRef);

  const { visit, visitId, needToLoadVisit } = useActiveVisit(clinicId);
  const [visitToken, setVisitToken] = useState("");
  const [pressedStop, setPressedStop] = useState(false);
  const [realTime, setRealTime] = useState(true);

  const [saveNotes] = useUpdateVisitNotesMutation();
  const [updateAssessment] = useUpdateVisitAssessmentMutation();

  const patient = useSelector(
    (state: RootState) => state.currentPatient.patient
  );

  const visitDetails = useSelector((state: RootState) => state.visit.visit);

  const VitalsDetails = useSelector((state: RootState) => state.visit.vitals);

  // console.log("[TESTING] current user (through redux) is: ", patient);
  // console.log("[TESTING] current visit (through redux) is: ", visitDetails);
  // console.log("[TESTING] current vitals (through redux) is: ", VitalsDetails);

  const { data: patientData } = usePatientQuery({
    id: patient?.patientId || "",
    patientIdType: patient?.patientIdType || "",
  });

  // console.log("[TESTING] PATIENT DATA IS: ", patientData);

  const { data: vitalsData } = useVitalsQuery({
    eventType: "V",
    eventId: visit?.visitId || "",
    patientId: patient?.patientId || "",
    patientIdType: patient?.patientIdType || "",
  });
  const vitals = vitalsData?.vitals;

  // console.log("[TESTING] VITALS IS: ", vitals );

  const { data: medicationData } = usePatientMedicationQuery({
    id: visit?.patientId || "",
    patientIdType: visit?.patientIdType || "",
  });
  const medications = useMemoizedRemoveNils(
    medicationData?.patient?.medications
  );

  // console.log("[TESTING] MEDICATION DATA IS: ",  medicationData);

  const { data: VisitData } = useVisitByIdQuery({
    clinicId: clinicId,
    visitId: visit?.visitId || "",
  });

  // console.log("[TESTING] VISIT DATA IS: ", VisitData);

  const createVisitFromToken = useCallback(() => {
    const visitInfo: InitiateVisitInput = {
      visitId: envService.randomUUID(),
      clinicId,
      patientId: visitToken,
      patientIdType: "TOKEN",
      createdAt: envService.currentTime(),
      assocPhysician: currentUser,
      chiefComplaint: VisitData?.visit?.chiefComplaint || "",
      reasonForComing: VisitData?.visit?.reasonForComing || "",
      noteToPhysician: VisitData?.visit?.noteToPhysician || "",
    };
    dispatch(
      setVisit({ visit: visitInfo, vitals: vitalsData?.vitals || undefined })
    );
    return initiateVisit({ visitInfo }) as Promise<InitiateVisitResult>;
  }, [clinicId, dispatch, currentUser, envService, initiateVisit, visitToken]);

  // const onDone = useCallback(() => {
  //   navigate("/plan");
  // }, [navigate]);
  const onDone = useCallback(() => {
    dispatch(deactivateBins());
    window.location.reload();
  }, []);

  const {
    recordingInProgress,
    transcription,
    questions,
    bins,
    startProcessing,
    stopProcessing,
    audioSessionId,
    waitingForAssessment,
    assessmentReady,
  } = useAudioProcessing(clinicId, visitId); //bin state created and bins are being returned.

  const onStart = useCallback(() => {
    async function onStartAsync() {
      const token = await createVisitFromToken();
      if (token.data) {
        startProcessing(token.data?.initiateVisit?.visitId);
      } else {
        console.log(`failed to create visit: ${token.error?.message || "?"}`);
        enqueueSnackbar("Failed to start visit");
      }
    }
    if (recordingOnly) {
      console.log("start processing for recordig only");
      startProcessing();
    } else {
      console.log("start processing with visit");
      void onStartAsync();
    }
  }, [createVisitFromToken, recordingOnly, startProcessing]);

  const onStop = useCallback(() => {
    setPressedStop(true);
    stopProcessing();
  }, [stopProcessing, setPressedStop]);

  const readyToStartRecording = audioSessionId.length > 0;
  const { notes, revisedNotes, symptoms } = useSelector((state: RootState) => {
    return state.assessment;
  });

  console.log(`now my notes are ${notes || "---"}`);

  const onAssessmentUpdate = useCallback(
    (assessment: AssessmentInput) => {
      console.log("about to update Assessment\n", assessment);
      if (visitId) {
        updateAssessment({ visitId, clinicId, assessment })
          .then((res) => {
            console.log(res);
          })
          .catch((error) => console.log(error));
      }
    },
    [updateAssessment, visitId, clinicId]
  );
  // onAssessmentUpdate should be used for updating the notes as well,
  // but we can deal with it later
  const onUpdateNotes = useCallback(
    (revisedNotes: string) => {
      if (visitId) {
        const visit = { revisedNotes };
        void saveNotes({
          visitId,
          clinicId,
          visit,
        });
      }
      console.log(`updating notes to be ${revisedNotes}`);
      if (revisedNotes) {
        dispatch(updateNotes(revisedNotes));
      }
    },
    [clinicId, dispatch, saveNotes, visitId]
  );

  if (needToLoadVisit) {
    return <SehatSpinner />;
  }

  return (
    <SimpleVisitInProgressUiView
      vitals={vitals ? vitals : VitalsDetails}
      chiefComplaint={VisitData?.visit?.chiefComplaint}
      reasonForComing={VisitData?.visit?.reasonForComing}
      noteToPhysician={VisitData?.visit?.noteToPhysician}
      medications={medications}
      patient={patientData}
      onStart={onStart}
      onStop={onStop}
      onDone={onDone}
      realTime={realTime}
      setRealTime={setRealTime}
      pressedStop={pressedStop}
      transcription={transcription}
      questions={questions}
      recordingInProgress={recordingInProgress}
      readyToStart={readyToStartRecording}
      planReady={assessmentReady}
      waitingForAssessment={waitingForAssessment}
      ref={scrollRef}
      symptoms={symptoms}
      notes={notes}
      revisedNotes={revisedNotes}
      bins={bins}
      recordingOnly={recordingOnly}
      visitToken={visitToken}
      setVisitToken={setVisitToken}
      updateNotes={onUpdateNotes}
      updateAssessment={onAssessmentUpdate}
    />
  );
}

interface SimpleVisitInProgressUiViewProps {
  patient: PatientQuery | undefined;
  vitals: Vitals | null | undefined;
  medications: MedicationInfo[];
  chiefComplaint: string | null | undefined;
  reasonForComing: string | null | undefined;
  noteToPhysician: string | null | undefined;
  realTime: boolean;
  setRealTime: (realTime: boolean) => void;
  onStart: () => void;
  onStop: () => void;
  onDone: () => void;
  pressedStop: boolean;
  recordingInProgress: boolean;
  planReady: boolean;
  waitingForAssessment: boolean;
  readyToStart: boolean;
  transcription: TranscriptionSection[];
  questions: string[] | null;
  bins: BinSet;
  symptoms: string[];
  notes?: string;
  revisedNotes?: string;
  recordingOnly?: boolean;
  visitToken: string;
  setVisitToken: (visitToken: string) => void;
  updateNotes: (s: string) => void;
  updateAssessment: (assessment: AssessmentInput) => void;
}

function SimpleVisitInProgressUiViewInternal(
  {
    recordingOnly,
    onStart,
    onStop,
    onDone,
    pressedStop,
    recordingInProgress,
    transcription,
    questions,
    vitals,
    patient,
    medications,
    chiefComplaint,
    reasonForComing,
    noteToPhysician,
    bins,
    readyToStart,
    planReady,
    waitingForAssessment,
    symptoms,
    notes,
    revisedNotes,
    visitToken,
    setVisitToken,
    updateNotes,
    updateAssessment,
  }: SimpleVisitInProgressUiViewProps,
  ref: React.ForwardedRef<HTMLUListElement>
) {
  const intl = useIntl();
  const readyToRecord = visitToken !== "";
  // return <BinsLayout />;
  return (
    <Stack spacing="1em" ml="1em" height="100%">
      {recordingOnly && (
        <Typography variant="h4">Recording for Training Purposes</Typography>
      )}
      <VitalsBar vitals={vitals} patient={patient} />
      <Stack direction="row">
        <RecordingButtonRow
          onStart={onStart}
          onStop={onStop}
          pressedStop={pressedStop}
          recordingInProgress={recordingInProgress}
          readyToStart={readyToRecord}
          waitingForModel={!readyToStart}
          enableDone={planReady}
        />
        <TextField
          label={intl.formatMessage({ id: "visitInProgress.identifier" })}
          value={visitToken}
          sx={{ width: "20em", ml: "1em", mb: "1em" }}
          required={true}
          onChange={textChangeHandler(setVisitToken)}
        />
      </Stack>
      <SimpleVisitInfoPanel
        vitals={vitals}
        chiefComplaint={chiefComplaint}
        reasonForComing={reasonForComing}
        noteToPhysician={noteToPhysician}
        transcription={transcription}
        transcriptionHeight="100%"
        transcriptionListHeight="100%"
        transcriptionInProgress={recordingInProgress}
        notes={notes}
        revisedNotes={revisedNotes}
        symptoms={symptoms}
        bins={bins}
        onDone={onDone}
        enableDone={planReady}
        waiting={waitingForAssessment}
        updateNotes={updateNotes}
        updateAssessment={updateAssessment}
        visitToken={visitToken}
      />
    </Stack>
  );
}

const SimpleVisitInProgressUiView = forwardRef(
  SimpleVisitInProgressUiViewInternal
);

type SimpleVisitInfoPanelProps = TranscriptionPanelProps &
  VisitDoneButtonProps & {
    vitals: Vitals | null | undefined;
    chiefComplaint: string | null | undefined;
    reasonForComing: string | null | undefined;
    noteToPhysician: string | null | undefined;
    notes?: string;
    revisedNotes?: string;
    updateNotes: (notes: string) => void;
    symptoms: string[];
    waiting: boolean;
    bins: BinSet;
    updateAssessment: (assessment: AssessmentInput) => void;
    visitToken: string;
  };

function SimpleVisitInfoPanel({
  vitals,
  chiefComplaint,
  reasonForComing,
  noteToPhysician,
  notes,
  revisedNotes,
  symptoms,
  onDone,
  enableDone,
  waiting,
  bins,
  updateNotes,
  updateAssessment,
  visitToken,
  ...transcriptionArgs
}: SimpleVisitInfoPanelProps) {
  const [tabState, setTabState] = useState("notes");
  const [leftIndexes, setLeftIndexes] = useState([1]);
  const [rightIndexes, setRightIndexes] = useState([4, 12, 7]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabState(newValue);
  };

  const moveBinAcross = (bin: number, fromLeft: boolean) => {
    // console.log("moving bin across: ", bin);
    // console.log("from left (in movebinacrross function): ", fromLeft);
    if (!fromLeft) {
      const updatedRightIndexes = Array.from(new Set([bin, ...rightIndexes]));
      const updatedLeftIndexes = leftIndexes.filter((i) => i !== bin);
      setRightIndexes(updatedRightIndexes);
      setLeftIndexes(updatedLeftIndexes);
    } else {
      const updatedLeftIndexes = Array.from(new Set([bin, ...leftIndexes]));
      const updatedRightIndexes = rightIndexes.filter((i) => i !== bin);
      setLeftIndexes(updatedLeftIndexes);
      setRightIndexes(updatedRightIndexes);
    }
    // console.log("right indexes are: ", rightIndexes);
    // console.log("left indexes are: ", leftIndexes);
  };
  // console.log("[testing SHXBIN] At the simplevisitinprogress, the bins is:  ", bins);
  return (
    <>
      <MediaQuery minWidth={1024}>
        <Stack direction="row" spacing="20px" mx="10px">
          <Stack direction="column" width="25%" sx={{ rowGap: 2, ml: "3%" }}>
            <BinCardStack
              align="left"
              binSet={bins}
              revisedBinSet={bins}
              updateAssessment={updateAssessment}
              assessmentReady={enableDone}
              isEditable={true}
              isComparable={true}
              Indexes={leftIndexes}
              moveBinAcross={(bin) => moveBinAcross(bin, true)}
              visitToken={visitToken}
            />
            <TranscriptionPanel {...transcriptionArgs} />
          </Stack>
          <Box width="40%">
            <DiagnosisPanel
              notes={notes || ""}
              revisedNotes={revisedNotes || ""}
              symptoms={symptoms}
              waiting={waiting}
              updateNotes={updateNotes}
            />
            {/* <div style={{
              position: 'fixed',
              bottom: 0,
              width: '40%',
              zIndex: 1000
            }}>
            <AmchurCard title="Patient's Concerns" role="primary" titleAlign="left" inTextAlign="left">
            <Typography variant="h4" sx={{ color: "gray", mb: 0 }}>
              Chief Complaint:
            </Typography>
            <Typography variant="body1" sx={{ color: "black", mb: 1 }}>
              {chiefComplaint}
            </Typography>
            <Typography variant="h4" sx={{ color: "gray", mb: 0 }}>
              Reason for Coming:
            </Typography>
            <Typography variant="body1" sx={{ color: "black", mb: 1 }}>
              {reasonForComing}
            </Typography>
            <Typography variant="h4" sx={{ color: "gray", mb: 0 }}>
              Note to Physician:
            </Typography>
            <Typography variant="body1" sx={{ color: "black", mb: 1 }}>
              {noteToPhysician}
            </Typography>
            </AmchurCard>  
            </div> */}
          </Box>
          <Stack direction="column" width="25%" sx={{ rowGap: 2 }}>
            <BinCardStack
              align="right"
              binSet={bins}
              revisedBinSet={bins}
              updateAssessment={updateAssessment}
              assessmentReady={enableDone}
              isEditable={true}
              isComparable={true}
              Indexes={rightIndexes}
              moveBinAcross={(bin) => moveBinAcross(bin, false)}
              visitToken={visitToken}
            />
            <VisitDoneButton onDone={onDone} enableDone={enableDone} />
          </Stack>
        </Stack>
      </MediaQuery>
      <MediaQuery maxWidth={1023}>
        <Stack spacing="10px" width="80%">
          <Tabs
            value={tabState}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="Transcription" value="transcript" />
            <Tab label="Notes" value="notes" />
          </Tabs>
          {tabState === "transcript" && (
            <TranscriptionPanel {...transcriptionArgs} />
          )}
          {tabState === "notes" && (
            <DiagnosisPanel
              notes={notes || ""}
              revisedNotes={revisedNotes || ""}
              symptoms={symptoms}
              waiting={waiting}
              updateNotes={updateNotes}
            />
          )}
          <VisitDoneButton onDone={onDone} enableDone={enableDone} />
        </Stack>
      </MediaQuery>
    </>
  );
}

interface VisitDoneButtonProps {
  onDone: () => void;
  enableDone: boolean;
}
function VisitDoneButton({ onDone, enableDone }: VisitDoneButtonProps) {
  return (
    <Box my="10px">
      <Button
        sx={{ ml: "2em" }}
        variant="contained"
        onClick={onDone}
        disabled={!enableDone}
      >
        <FormattedMessage id="visitInProgress.visitDone" />
      </Button>
    </Box>
  );
}
export default SimpleVisitInProgressRoute;
