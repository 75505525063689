import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface BinsActiveState {
    BinsActivate: boolean;
}

const initialState: BinsActiveState = {
    BinsActivate: false, //true for testing offline, convert to false before deploying
}

const binsActiveSlice = createSlice({
    name: "binsActive",
    initialState,
    reducers: {
        activateBins(state) {
            state.BinsActivate = true;
        },
        deactivateBins(state) {
            state.BinsActivate = false;
        },
        toggleBins(state) {
            state.BinsActivate = !state.BinsActivate;
        },

    }
});

export const {activateBins, deactivateBins, toggleBins} = binsActiveSlice.actions

export default binsActiveSlice.reducer