import React from "react";
import { TextField, Typography, TextFieldProps } from "@mui/material";

interface DateFieldProps extends Omit<TextFieldProps, "type"> {
  name: string;
  label: string;
  required?: boolean;
}

const DateField: React.FC<DateFieldProps> = ({
  name,
  label,
  error = false,
  helperText = "",
  value,
  onChange,
  required,
  ...rest
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        width: "100%",
      }}
    >
      <Typography
        variant="body2"
        fontSize="clamp(12px, 0.8vw, 16px)"
        color="text.secondary"
        textAlign={"left"}
        sx={{ marginLeft: "4px" }}
      >
        {label}
        {required && <span style={{ color: "red", marginLeft: "4px" }}>*</span>}
      </Typography>
      <TextField
        name={name}
        type="date"
        fullWidth
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
        {...rest}
        InputProps={{
          sx: {
            height: "4vh",
            border: "1px solid #AEBECD",
            backgroundColor: "white",
            fontSize: "clamp(15px, 0.8vw, 24px)",
            borderRadius: "8px",
            textAlign: "left",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:focus .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
        }}
      />
    </div>
  );
};

export default DateField;
