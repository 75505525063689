import React from "react";
import { Card, CardHeader, CardContent } from "@mui/material";

interface BinProps {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
}

const Bin: React.FC<BinProps> = ({ title, icon, children }) => {
  return (
    <Card
      sx={{
        borderRadius: "25px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
      }}
    >
      <CardHeader
        avatar={icon}
        title={title}
        titleTypographyProps={{
          variant: "h6",
          sx: { fontWeight: "bold", color: "white" },
        }}
        sx={{
          backgroundColor: "#AAD8F2",
          padding: "8px 16px",
        }}
      />
      <CardContent sx={{ padding: 1 }}>{children}</CardContent>
    </Card>
  );
};

export default Bin;
