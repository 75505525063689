/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack } from "@mui/material";
import Bin from "../HelperComponents/BinCardV2"; //@ts-ignore
import Alert from "../ui-components/Alert";
import { RootState } from "../../../../state/reducers";
import { assignCategoriesToAllergies } from "../../../../utils/allergies";
import { ReactComponent as AllergiesIcon } from "./../../../../assets/AllergiesIcon.svg";
import AddOrEditAllergy from "./AddOrEditAllergy";
import ListView from "./ListView";
import useAllergies from "./useAllergy";

//importing the relevant reducer
import { setAllergenNames } from "../../../../state/AllergiesBinSlice";

interface IAllergiesProps {
  data?: any[];
  visitToken?: string;
}

const Allergies: React.FC<IAllergiesProps> = ({ data, visitToken = "" }) => {
  const currentPhysician: any = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  );
  const [allergies, setAllergies] = useState<any[]>([]);
  const [isAddOrEditMode, setIsAddOrEditMode] = useState(false);
  const [selectedAllergy, setSelectedAllergy] = useState({});
  const [selectedAllergyIndex, setSelectedAllergyIndex] = useState(-1);
  const [alertMsg, setAlertMsg] = useState("");
  const dispatch = useDispatch();
  const allergenNames = useSelector((state: RootState) => state.allergiesBin.allergenNames);

  const { benchmark, setVisitToken } = useAllergies();

  const closeAddOrEditView = () => {
    setIsAddOrEditMode(false);
    setSelectedAllergy({});
    setSelectedAllergyIndex(-1);
  };

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const onSave = (newAllergy: any) => {
    let updatedAllergies = [];

    if (selectedAllergyIndex >= 0 && selectedAllergyIndex < allergies.length) {
      updatedAllergies = allergies.map((allergy, index) =>
        index === selectedAllergyIndex ? { ...allergy, ...newAllergy } : allergy
      );
      setAlertMsg("Allergy was edited successfully");
    } else {
      updatedAllergies = [...allergies, newAllergy];
      setAlertMsg("Allergy was added successfully");
    }
    setAllergies(updatedAllergies);
    benchmark(updatedAllergies);
    Object.keys(selectedAllergy).length === 0
      ? setAlertMsg("Allergy was added successfully")
      : setAlertMsg("Allergy was edited successfully");
    setShowSuccessMessage(true);
    closeAddOrEditView();
  };

  const onDelete = (allergyToBeDeleted: any) => {
    const updatedAllergies = allergies.filter(
      (allergy: any) => allergy.Allergen !== allergyToBeDeleted.Allergen
    );
    setAllergies(updatedAllergies);
    closeAddOrEditView();
    setShowSuccessMessage(true);
    benchmark(updatedAllergies);
    setAlertMsg("Allergy was deleted successfully!");
  };

  const onClickAllergy = (allergy: any, index: number) => {
    setSelectedAllergy(allergy);
    setIsAddOrEditMode(true);
    setSelectedAllergyIndex(index);
  };

  const onMarkAsReviewed = (reviewed: boolean) => {
    if (reviewed) {
      const today = new Date();

      const updatedAllergies = allergies.map((allergy) => ({
        ...allergy,
        reviewedAt: today,
        reviewedBy: currentPhysician,
      }));

      setAllergies(updatedAllergies);
      benchmark(updatedAllergies);
    }
  };
  useEffect(() => {
    if (data) {
      console.log("in allergies", data);

      const processAllergies = async () => {
        try {
          const updatedAllergies = await assignCategoriesToAllergies(data);
          setAllergies(updatedAllergies);
        } catch (error) {
          console.error("Error processing allergies:", error);
        }
      };
      // Call the async function
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      processAllergies();
    }
  }, [data]);

  useEffect(() => {
    setVisitToken(visitToken);
  }, [visitToken]);

  // Function to update allergen names (Hassan)
  const updateAllergenNames = () => {
    const names = allergies.map((allergy) => allergy.Allergen);
    dispatch(setAllergenNames(names));
  };

  // Update allergenNames whenever allergies changes (Hassan)
  useEffect(() => {
    updateAllergenNames();
  }, [allergies]);

  return (
    <Bin title="Allergies" icon={<AllergiesIcon />}>
      {isAddOrEditMode ? (
        <Box
          width="100%"
          sx={{
            backgroundColor: "white",
          }}
        >
          <AddOrEditAllergy
            allAllergens={allergies.map((allergy) => allergy.Allergen) || []}
            isAdd={Object.keys(selectedAllergy).length === 0}
            selectedAllergy={selectedAllergy}
            onClose={closeAddOrEditView}
            onSave={onSave}
            onDelete={onDelete}
          />
        </Box>
      ) : (
        <Stack width={"100%"} flexDirection={"column"} spacing={1}>
          <ListView
            allergies={allergies}
            onClickHandler={onClickAllergy}
            addButtonClickHandler={() => setIsAddOrEditMode(true)}
            onReviewHandler={onMarkAsReviewed}
          />
        </Stack>
      )}
      {showSuccessMessage && (
        <Alert
          msg={alertMsg}
          setShowAlert={setShowSuccessMessage}
          type={"success"}
        />
      )}
    </Bin>
  );
};

export default Allergies;
