export default function CleanBin(Bin: any, title: string): any {
    // console.log("CleanBin called, with args: ", JSON.stringify(Bin), title)
    if (title === "meds") {
        // console.log("Meds cleanup invoked in CleanBin")
        let BinArray = Array.isArray(Bin) ? [...Bin] : [Bin];
        let fields = [
          'Trade_Name', 'Salt', 'Number', 'Unit', 'Route', 'Frequency', 'Start_date', 'End_date', 'Prescribed_by', 'Deleted', 'Delete_Reason', 'Discontinued_by', 'Reviewed_by', 'Reviewed_at', 'Note'
        ];
        let correctedArray = BinArray.map((originalItem) => {
          let item = { ...originalItem }; 
          if (typeof item !== 'object' || item === null || Array.isArray(item)) {
            return null;
          }
          for (const field of fields) {
            if (!(field in item)) {
              item[field] = '';
            }
          }
          for (const key in item) {
            if (typeof item[key] !== 'string') {
              item[key] = '';
            }
            try {
              if (key === "Deleted") {
                if (item[key] !== "true") {
                  item[key] = "false";
                }
              } else if (key === "Start_date" && item[key] === "") {
                item[key] = new Date().toISOString().split("T")[0];
              } else if (key === "End_date" && item[key] === "") {
                item[key] = "Ongoing";
              } 
            } catch (e) {
            }
          }
          return item; // Return the corrected item
        }).filter(Boolean); 
        
        return correctedArray;
    } else if (title === "familyHx") {
        let BinArray = Array.isArray(Bin) ? [...Bin] : [Bin]; // Type correcting the data received from model.
        let fields = [
          'Relation', 'Status', 'Age_at_death', 'Medical_condition',  'Additional_notes', 'Reviewed_by', 'Reviewed_at'
        ];
        let correctedArray = BinArray.map((originalItem) => {
          let item = { ...originalItem }; // Create a shallow copy of the item
          if (typeof item !== 'object' || item === null || Array.isArray(item)) {
            return null;
          }
          for (const field of fields) {
            if (!(field in item)) {
              if (field === "Medical_condition") {
                item[field] = [];
              } else{
                item[field] = '';
              }
            }
          }
          for (const key in item) {
            if (key !== "Medical_condition" && typeof item[key] !== 'string') {
              item[key] = '';
            } else if (key === "Medical_condition" && Array.isArray(item[key]) === false ) {
              item[key] = [];
            }
          }
          return item; // Return the corrected item
        }).filter(Boolean); // Filter out any null values

        return correctedArray;
          
          
    }else {
      return Bin;
    }

}