import * as R from "ramda";
import { useMemo, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { InputBaseProps } from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import {
  type RecordingStatuses,
  nullaryBool,
  limitToRecordingStatuses,
} from "shared-utils";
import { type Recording } from "../../graphql/generated";
import { toFormattedCnic } from "../../domain/cnicUtils";
import { textareaChangeHandler } from "../../utils/eventUtils";
import { type AppDispatch } from "../../state/store";
import { getRecordingDownloadUrlMiddleware } from "../../middleware/recordingMiddleware";
import {
  useUpdateRecordingNotesMutation,
  useUpdateRecordingStatusMutation,
} from "../../hooks/apiHooks/recordingMutationHooks";
import { type RecordingModel } from "../../models/modelTypes";
import { useServiceBag } from "../../services/ServiceBag";
import { type FileService } from "../../services/fileService";
import useCurrentRole from "../../hooks/useCurrentRole";
import {
  useRecordingsByCriteriaQuery,
  useUsersForRoleQuery,
} from "../../hooks/apiHooks/queryHooks";
import TextAutocompleteField from "../../components/TextAutocompleteField";
import SelectFromMenu from "../../components/SelectFromMenu";
import TranscriptionDialog from "./TranscriptionDialog";

export default function RecordingRoute() {
  const { recordingId } = useParams();
  const { fileService } = useServiceBag();

  const { data: recordings, isSuccess } = useRecordingsByCriteriaQuery({
    criteria: { since: "2021-10-01" },
  });

  const { data: users } = useUsersForRoleQuery({ role: "trn" });
  console.log(`users are ${JSON.stringify(users)}`);

  const recording = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (recordings || []).find((r: Recording) => r.recordingId === recordingId),
    [recordings, recordingId]
  );
  console.log(`recordings: ${JSON.stringify(recordings)}`);

  if (isSuccess) {
    if (recording == null) {
      return (
        <Box>
          <Typography>{recordingId} not found</Typography>
        </Box>
      );
    }
    return (
      <RecordingStateView
        recording={recording}
        assignees={users}
        fileService={fileService}
      />
    );
  }
  return <Typography>Loading...</Typography>;
}

interface RecordingStateViewProps {
  recording: RecordingModel;
  assignees: string[];
  fileService: FileService;
}

function RecordingStateView({
  recording,
  assignees,
  fileService,
}: RecordingStateViewProps) {
  const dispatch = useDispatch<AppDispatch>();
  const isAdmin = useCurrentRole() === "su";

  const [status, setStatus] = useState<RecordingStatuses>(
    recording.status || "new"
  );
  const [assignee, setAssignee] = useState(recording.assignedTo || null);
  const [statusChanged, setStatusChanged] = useState(false);

  const [originalTranscription, setOriginalTranscription] = useState(
    recording.originalTranscription || ""
  );
  const [correctedTranscription, setCorrectedTranscription] = useState(
    recording.correctedTranscription || ""
  );
  const [altTranscription, setAltTranscription] = useState(
    recording.altTranscription || ""
  );
  const [notes, setNotes] = useState(recording.notes || "");
  const [notesChanged, setNotesChanged] = useState(false);

  const [updateRecordingNotes] = useUpdateRecordingNotesMutation();
  const { updateRecordingStatus } = useUpdateRecordingStatusMutation();

  const handleStatusChange = (status: RecordingStatuses) => {
    setStatus(status);
    setStatusChanged(true);
    if (status === "new") {
      setAssignee(null);
    }
  };

  const handleAssigneeChange = (assignee: string | null) => {
    setAssignee(assignee);
    setStatusChanged(true);
    if (assignee != null && status === "new") {
      setStatus("assigned");
    } else if (assignee == null) {
      setStatus("new");
    }
  };

  const propogateNoteChange = (setter: (newValue: string) => void) => {
    return (v: string) => {
      setter(v);
      setNotesChanged(true);
    };
  };

  const onSave = () => {
    if (!recording.recordingId || !recording.user) {
      return;
    }
    if (statusChanged) {
      const p = updateRecordingStatus({
        keys: { recordingId: recording.recordingId, user: recording.user },
        update: { status: status, assignedTo: assignee || undefined },
      });
      p.then(() => {
        setStatusChanged(false);
      }).catch((e: Error) => console.log(`error: ${e.message}`));
    }

    if (notesChanged) {
      const p = updateRecordingNotes({
        keys: { recordingId: recording.recordingId, user: recording.user },
        update: {
          originalTranscription,
          correctedTranscription,
          altTranscription,
          notes,
        },
      });
      p.then(() => {
        setStatusChanged(false);
      }).catch((e: Error) => console.log(`error: ${e.message}`));
    }
  };

  const recordingId = recording.recordingId;

  const onDownload = useCallback(() => {
    if (!recordingId) {
      return;
    }
    void dispatch(getRecordingDownloadUrlMiddleware(recordingId)).then(
      (result) => {
        if (getRecordingDownloadUrlMiddleware.fulfilled.match(result)) {
          if (result.payload) {
            fileService.downloadFromS3(
              result.payload,
              `recording-${recordingId}}.webm`
            );
          }
        }
      }
    );
  }, [recordingId, dispatch, fileService]);

  return (
    <RecordingUiView
      recording={recording}
      assignees={assignees}
      status={status || "new"}
      setStatus={handleStatusChange}
      assignee={assignee}
      setAssignee={handleAssigneeChange}
      originalTranscription={originalTranscription}
      setOriginalTranscription={propogateNoteChange(setOriginalTranscription)}
      correctedTranscription={correctedTranscription}
      setCorrectedTranscription={propogateNoteChange(setCorrectedTranscription)}
      altTranscription={altTranscription}
      setAltTranscription={propogateNoteChange(setAltTranscription)}
      notes={notes}
      setNotes={propogateNoteChange(setNotes)}
      onSaveClicked={onSave}
      onDownloadClicked={onDownload}
      isAdmin={isAdmin}
    />
  );
}

interface TranscriptionTextFieldProps {
  title: string;
  value: string;
  setValue: (newValue: string) => void;
  placeholder: string;
  disabled: boolean;
  inputProps?: InputBaseProps["inputProps"];
}

function TranscriptionTextField({
  title,
  value,
  setValue,
  placeholder,
  disabled,
  inputProps,
}: TranscriptionTextFieldProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openDialog, closeDialog] = nullaryBool(setDialogOpen);

  return (
    <FormControl sx={{ width: "30%" }}>
      <FormLabel>
        <Stack direction="row" justifyContent="space-between">
          <Typography>{title}</Typography>
          <IconButton onClick={openDialog} disabled={disabled}>
            <OpenInFullIcon />
          </IconButton>
        </Stack>
      </FormLabel>
      <TextField
        multiline
        value={value}
        onChange={textareaChangeHandler(setValue)}
        placeholder={placeholder}
        minRows={12}
        disabled={disabled}
        inputProps={inputProps}
      />
      <TranscriptionDialog
        open={dialogOpen}
        onClose={closeDialog}
        title={title}
        value={value}
        setValue={setValue}
        placeholder={placeholder}
        inputProps={inputProps}
      />
    </FormControl>
  );
}
interface RecordingUiViewProps {
  recording: Recording;
  assignees: string[];
  status: RecordingStatuses;
  setStatus: (status: RecordingStatuses) => void;
  assignee: string | null;
  setAssignee: (assignee: string | null) => void;
  originalTranscription: string;
  setOriginalTranscription: (originalTranscription: string) => void;
  correctedTranscription: string;
  setCorrectedTranscription: (correctedTranscription: string) => void;
  altTranscription: string;
  setAltTranscription: (altTranscription: string) => void;
  notes: string;
  setNotes: (notes: string) => void;
  isAdmin: boolean;
  onSaveClicked: () => void;
  onDownloadClicked: () => void;
}
function RecordingUiView({
  recording,
  assignees,
  status,
  setStatus,
  assignee,
  setAssignee,
  originalTranscription,
  setOriginalTranscription,
  correctedTranscription,
  setCorrectedTranscription,
  altTranscription,
  setAltTranscription,
  notes,
  setNotes,
  isAdmin,
  onSaveClicked,
  onDownloadClicked,
}: RecordingUiViewProps) {
  const onlyNewAllowed = assignee == null;

  const setStatusFromString = R.compose(setStatus, limitToRecordingStatuses);

  return (
    <Stack alignItems="flex-start" marginX="10em" marginTop="2em">
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Typography>Id: {recording.recordingId}</Typography>
        <Button variant="contained" onClick={onSaveClicked}>
          Save
        </Button>
      </Stack>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Typography>
          Patient: {toFormattedCnic(recording.patientId || "")}
        </Typography>
        <Button
          variant="contained"
          onClick={onDownloadClicked}
          sx={{ marginTop: "1em" }}
        >
          Download
        </Button>
      </Stack>
      <Typography>{recording.user}</Typography>
      {isAdmin && (
        <TextAutocompleteField
          choices={assignees}
          value={assignee || null}
          setValue={setAssignee}
          label="Assignee"
        />
      )}
      <SelectFromMenu
        value={status}
        setValue={setStatusFromString}
        label="Status"
      >
        <MenuItem key="new" value="new" disabled={!isAdmin}>
          New
        </MenuItem>
        <MenuItem key="assigned" value="assigned" disabled={onlyNewAllowed}>
          Assigned
        </MenuItem>
        <MenuItem key="reviewed" value="reviewed" disabled={onlyNewAllowed}>
          Review Completed
        </MenuItem>
      </SelectFromMenu>

      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        marginTop="2em"
      >
        <TranscriptionTextField
          title="Original Transcription"
          value={originalTranscription}
          setValue={setOriginalTranscription}
          placeholder="Original transcription"
          disabled={!isAdmin}
        />
        <TranscriptionTextField
          title="Corrected English Transcription"
          value={correctedTranscription}
          setValue={setCorrectedTranscription}
          placeholder="English"
          disabled={false}
        />
        <TranscriptionTextField
          title="Corrected Urdu Transcription"
          value={altTranscription}
          setValue={setAltTranscription}
          placeholder="اردو"
          disabled={false}
          inputProps={{ style: { textAlign: "right" } }}
        />
      </Stack>

      <FormControl sx={{ marginTop: "2em", width: "60%" }}>
        <FormLabel>Notes</FormLabel>
        <TextareaAutosize
          value={notes}
          onChange={textareaChangeHandler(setNotes)}
          placeholder="Additional notes"
          minRows={6}
        />
      </FormControl>
    </Stack>
  );
}
