/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { getFieldOptions } from "../../../../utils/BinUtils";
import SelectField from "../ui-components/SelectField";
import DateField from "../ui-components/DateField";
import SearchField from "../ui-components/SearchField";
import TextField from "../ui-components/TextField";
import TileInputField from "../ui-components/MultiSelectField";
import { fetchCSV } from "../../../../utils/allergies";

interface AllergyData {
  Allergen: string;
  Reaction: string[];
  Category: string;
  Start_date: string;
  isOngoing: boolean;
  End_date: string;
  Been_to_er: string;
  Last_er_visit: string;
  Additional_notes: string;
}

interface AddOrEditAllergyProps {
  isAdd?: boolean; // true for Add, false for Edit
  onSave: (formData: AllergyData) => void;
  onDelete: (formData: AllergyData) => void;
  onClose: () => void;
  selectedAllergy: Partial<AllergyData>;
  allAllergens: string[];
}

// Validation Schema
const allergySchema = Yup.object().shape({
  Allergen: Yup.string().required("Allergen is required"),
  CustomAllergen: Yup.string().when(["Allergen"], (Allergen, schema) => {
    return Allergen[0] === "Other"
      ? schema.required("Custom allergen is required")
      : schema.notRequired();
  }),
  Reaction: Yup.array()
    .of(Yup.string().required("Each reaction must be a string"))
    .min(1, "At least one reaction is required")
    .required("Reaction is required"),
  Category: Yup.string().required("Category is required"),
  Start_date: Yup.string().required("Date of onset is required"),
  isOngoing: Yup.boolean(),
  End_date: Yup.string(),
  Been_to_er: Yup.string(),
  Last_er_visit: Yup.string(),
  Additional_notes: Yup.string(),
});

const AddOrEditAllergy: React.FC<AddOrEditAllergyProps> = ({
  isAdd = true,
  onSave,
  onClose,
  onDelete,
  selectedAllergy,
  allAllergens = [],
}) => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<AllergyData>({
    defaultValues: {
      Allergen: selectedAllergy.Allergen || "",
      Reaction: selectedAllergy.Reaction || [],
      Category: selectedAllergy.Category || "",
      Start_date: selectedAllergy.Start_date || "",
      isOngoing: selectedAllergy.isOngoing || false,
      End_date: selectedAllergy.End_date || "",
      Been_to_er: selectedAllergy.Been_to_er || "",
      Last_er_visit: selectedAllergy.Last_er_visit || "",
      Additional_notes: selectedAllergy.Additional_notes || "",
    },
    resolver: yupResolver(allergySchema),
  });
  const today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    if (Object.keys(selectedAllergy).length > 0) {
      reset(selectedAllergy);
    }
  }, [selectedAllergy, reset]);

  const onSubmitHandler = (formData: AllergyData) => {
    onSave(formData);
  };

  const formValues = watch();
  const [drugOptions, setDrugOptions] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const saltNames = await fetchCSV();
      setDrugOptions(saltNames);
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, []);

  const allOptions = useMemo(() => {
    // Assuming getFieldOptions is available and fetches options
    const fieldOptions = [
      ...(getFieldOptions("allergies", "Food_Allergens") || []),
      ...(getFieldOptions("allergies", "Environmental_Allergens") || []),
      ...(getFieldOptions("allergies", "Chemical_Allergens") || []),
      ...(getFieldOptions("allergies", "Drug_Class") || []),
      "Other", // Adding "Other" option
      ...drugOptions, // Include the drug options (salt names)
    ];

    return fieldOptions;
  }, [drugOptions]);

  return (
    <Box width={"100%"}>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        mb={2}
      >
        <Stack flexDirection={"column"}>
          <Typography
            textAlign={"left"}
            fontSize={"14px"}
            fontWeight={400}
            fontStyle={"normal"}
            lineHeight={"normal"}
          >
            {isAdd ? "Add allergy" : "Edit allergy"}
          </Typography>
          <Typography
            textAlign={"left"}
            fontSize={"12px"}
            fontWeight={400}
            fontStyle={"normal"}
            lineHeight={"normal"}
            color={"#757575"}
          >
            Please {isAdd ? " add " : " edit "} allergy details
          </Typography>
        </Stack>
        <IconButton size="small" onClick={onClose}>
          <CloseOutlined sx={{ color: "red", width: 16, height: 16 }} />
        </IconButton>
      </Stack>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack
          bgcolor={"#F8F9FA"}
          padding={2}
          border={"1px solid #CCD4DB"}
          borderRadius={"3.63px"}
          gap={1}
          alignItems={"flex-start"}
        >
          <Controller
            name="Allergen"
            control={control}
            render={({ field }) => (
              <>
                <SearchField
                  {...field}
                  label="Allergen"
                  options={allOptions.filter(
                    (option) =>
                      option === selectedAllergy.Allergen ||
                      option === "Other" ||
                      !allAllergens.includes(option)
                  )}
                  placeholder="Search Allergens"
                  onChange={field.onChange}
                  value={field.value || ""}
                  required
                  error={!!errors.Allergen}
                  helperText={errors.Allergen?.message}
                />
                {formValues.Allergen === "Other" && (
                  <Controller
                    name="CustomAllergen"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Specify Allergen"
                        placeholder="Enter allergen name"
                        error={!!errors.CustomAllergen}
                        helperText={errors.CustomAllergen?.message}
                      />
                    )}
                  />
                )}
              </>
            )}
          />
          <Controller
            name="Reaction"
            control={control}
            render={({ field }) => (
              <TileInputField
                {...field}
                label="Reaction"
                options={getFieldOptions("allergies", "Reaction") || []}
                value={field.value}
                required
                onChange={field.onChange}
                error={!!errors.Reaction}
                helperText={errors.Reaction?.message}
              />
            )}
          />
          <Controller
            name="Category"
            control={control}
            render={({ field }) => (
              <SelectField
                {...field}
                label="Category"
                options={getFieldOptions("allergies", "Category") || []}
                value={field.value}
                required
                onChange={field.onChange}
                error={!!errors.Category}
                helperText={errors.Category?.message}
              />
            )}
          />
          <Stack
            width={"100%"}
            flexDirection={"column"}
            spacing={0}
            justifyContent={"flex-start"}
          >
            <Controller
              name="Start_date"
              control={control}
              render={({ field }) => (
                <DateField
                  {...field}
                  label="Date of Onset"
                  required
                  error={!!errors.Start_date}
                  helperText={errors.Start_date?.message}
                  inputProps={{
                    max: today,
                  }}
                />
              )}
            />
            <Controller
              name="isOngoing"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      {...field}
                      sx={{
                        marginX: 1,
                        marginTop: 0.5,
                        width: 12,
                        height: 12,
                        "& .MuiSvgIcon-root": {
                          fontSize: 12,
                        },
                      }}
                    />
                  }
                  label="Is the allergy ongoing?"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      color: "#B4B8BB",
                      fontSize: "12px",
                      fontWeight: "400",
                      marginTop: 0.5,
                    },
                  }}
                />
              )}
            />
          </Stack>
          {!formValues.isOngoing && (
            <Controller
              name="End_date"
              control={control}
              render={({ field }) => (
                <DateField
                  {...field}
                  label="End Date (If applicable)"
                  error={!!errors.End_date}
                  helperText={errors.End_date?.message}
                  inputProps={{
                    max: today,
                    min: formValues.Start_date,
                  }}
                />
              )}
            />
          )}
          <Controller
            name="Been_to_er"
            control={control}
            render={({ field }) => (
              <SelectField
                {...field}
                label="Have u ever been to the ER to get an injection of Solocortif or Epinephrine?"
                options={getFieldOptions("allergies", "Used_Epipen") || []}
                value={field.value}
                onChange={field.onChange}
                error={!!errors.Been_to_er}
                helperText={errors.Been_to_er?.message}
              />
            )}
          />
          {!(formValues.Been_to_er === "No") && (
            <Controller
              name="Last_er_visit"
              control={control}
              render={({ field }) => (
                <DateField
                  {...field}
                  label="When was the last time you went to the ER?"
                  InputLabelProps={{ shrink: true }}
                  required
                  error={!!errors.Last_er_visit}
                  helperText={errors.Last_er_visit?.message}
                />
              )}
            />
          )}
          <Controller
            name="Additional_notes"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Additional Notes"
                multiline
                rows={3}
              />
            )}
          />
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
            alignItems={"flex-start"}
            gap={2}
          >
            {!isAdd && (
              <Button
                sx={{
                  height: "22px",
                  color: "white",
                  borderRadius: "3.365px",
                  border: "0.841px solid var(--Greys-500, #AEBECD)",
                  backgroundColor: "var(--Red-2, #FF8080)",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  "&:hover": {
                    backgroundColor: "#FF6666", // Lighter red for hover
                  },
                }}
                onClick={() => onDelete(formValues)}
              >
                Delete
              </Button>
            )}
            <Button
              type="submit"
              sx={{
                height: "22px",
                color: "white",
                borderRadius: "3.365px",
                border: "0.841px solid var(--Greys-500, #AEBECD)",
                backgroundColor: "var(--green, #0CAF5D)",
                "&:hover": {
                  backgroundColor: "#0A9E51", // Slightly darker green for hover
                },
              }}
            >
              Save
            </Button>
          </Box>
        </Stack>
      </form>
    </Box>
  );
};

export default AddOrEditAllergy;
