import { Stack, Typography, IconButton, Menu, MenuItem, Box, Button, Checkbox, ListItemText, TextField } from '@mui/material';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { getFieldOptions, SocialHxActiveProps, SocialHxInActiveProps, MedsInActiveProps, MedsTradeProps, AllergiesInActiveProps } from "../utils/BinUtils";
import { FormControlLabel, Radio, RadioGroup, SelectChangeEvent } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import { RootState } from '../state/reducers';
import { useSelector } from "react-redux";
import GreenTick from "../assets/GreenTick.svg";
import PositiveTagIcon from "../assets/PositiveTagIcon.svg";
import NegativeTagIcon from "../assets/NegativeTagIcon.svg";
import WarningIcon from "../assets/WarningIcon.svg";


export function ErrorComponent({ handleCancel, name, dosageValue }: { handleCancel: () => void, name: string | JSX.Element, dosageValue: number }): JSX.Element {
    return (
      <div style={{ width: "70%", border: '1px solid #CCD4DB', borderRadius: '8px', padding: "10px", backgroundColor: "white" }}>
        <Stack direction = "row">
        <img src={WarningIcon} style={{ marginRight: 4, marginBottom: 0, width: '2vw', height: '2vw', color: 'red' }} />
        <Typography variant="h6" sx={{ color: 'black', textAlign: 'left', padding: '10px' }}>
          Error
        </Typography>
        </Stack>
        <Box sx={{ height: '2px', backgroundColor: 'red', marginBottom: '10px' }} />
  
        <Typography sx={{ color: 'gray', textAlign: 'left', padding: '10px' }}>
          The calculated Dosage of {name} is {dosageValue}, which exceeds the recommended limit (4000). Please adjust the dosage to proceed.
        </Typography>
       
        <Box sx={{ height: '1px', backgroundColor: 'gray', marginBottom: '10px' }} />
        <Stack direction="row" justifyContent="right" sx={{ padding: '10px' }}>
          <Button onClick={handleCancel} sx={{ backgroundColor: '#FCD9D9', color: '#FF8080', width: '45%', '&:hover': { backgroundColor: '#F8BABA', },  }}>
            Cancel
          </Button>
        </Stack>
      </div>
    );
  }
  
  export function WarningComponent({ handleCancel, handleSave, name, classMap }: { handleCancel: () => void, handleSave: () => void, name: string | JSX.Element, classMap: Record<string, string[]>; }): JSX.Element {
     // Function to find the class that the drug belongs to
    const findClassForDrug = (drugName: string) => {
        for (const [cls, tradeNames] of Object.entries(classMap)) {
          if (tradeNames.includes(drugName)) {
            return cls; // Return the class if the drug is in that class
          }
        }
        return null; // Return null if no class is found
      };

      // Function to get other drugs in the same class
      const getOtherDrugsInClass = (drugName: string, drugClass: string) => {
        const tradeNames = classMap[drugClass];
        return tradeNames.filter((name) => name !== drugName); // Exclude the current drug
      };

      const drugClass = findClassForDrug(name as string);

      // Get the other drugs from the same class
      const otherDrugs = drugClass ? getOtherDrugsInClass(name as string, drugClass) : [];

      
    return (
      <div style={{ width: "70%", border: '1px solid #CCD4DB', borderRadius: '8px', padding: "10px", backgroundColor: "white" }}>
        <Stack direction = "row">
        <img src={WarningIcon} style={{ marginRight: 4, marginBottom: 0, width: '2vw', height: '2vw', color: 'orange' }} />
        <Typography variant="h6" sx={{ color: 'black', textAlign: 'left', padding: '10px' }}>
          Warning
        </Typography>
        </Stack>
        <Box sx={{ height: '2px', backgroundColor: 'red', marginBottom: '10px' }} />
        <Typography sx={{ color: 'gray', textAlign: 'left', padding: '10px' }}>
        {drugClass ? (
          <>
            You have already added another drug "{otherDrugs.join(", ")}" from the "{drugClass}" class. Are you sure you want to continue?
          </>
        ) : (
          <>You have already added another drug from this drug class, are you sure you want to continue?</>
        )}
        </Typography>
        <Box sx={{ height: '1px', backgroundColor: 'gray', marginBottom: '10px' }} />
        <Stack direction="row" justifyContent="right" sx={{ padding: '10px' }}>
          <Button onClick={handleCancel} sx={{ backgroundColor: '#EC8B36', color: 'white', width: '45%', '&:hover': { backgroundColor: '#D9782F', }, }}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={handleSave} sx={{ borderColor: '#EC8B36', color: '#EC8B36', width: '45%',  '&:hover': { backgroundColor: 'rgba(236, 139, 54, 0.1)', borderColor: '#D9782F', color: '#D9782F', }, }}>
            Save
          </Button>
        </Stack>
      </div>
    );
  }

  
  export function MedsAllergyWarning({ handleCancel, handleSave, medName, className }: { handleCancel: () => void, handleSave: () => void, medName: string, className: string }): JSX.Element {
    return (
      <div style={{ width: "70%", border: '1px solid #CCD4DB', borderRadius: '8px', padding: "10px", backgroundColor: "white" }}>
        <Stack direction = "row">
        <img src={WarningIcon} style={{ marginRight: 4, marginBottom: 0, width: '2vw', height: '2vw', color: 'orange' }} />
        <Typography variant="h6" sx={{ color: 'black', textAlign: 'left', padding: '10px' }}>
          Warning
        </Typography>
        </Stack>
        <Box sx={{ height: '2px', backgroundColor: 'red', marginBottom: '10px' }} />
        <Typography sx={{ color: 'gray', textAlign: 'left', padding: '10px' }}>
        {medName} belongs to a drug class that is listed as an allergy. Are you sure you want to continue?
        </Typography>
        <Box sx={{ height: '1px', backgroundColor: 'gray', marginBottom: '10px' }} />
        <Stack direction="row" justifyContent="right" sx={{ padding: '10px' }}>
          <Button onClick={handleCancel} sx={{ backgroundColor: '#EC8B36', color: 'white', width: '45%', '&:hover': { backgroundColor: '#D9782F', }, }}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={handleSave} sx={{ borderColor: '#EC8B36', color: '#EC8B36', width: '45%',  '&:hover': { backgroundColor: 'rgba(236, 139, 54, 0.1)', borderColor: '#D9782F', color: '#D9782F', }, }}>
            Save
          </Button>
        </Stack>
      </div>
    );
  }
   
  
  