export interface SocialHxActiveProps {
  Bin: {
    [key: string]: string | undefined;
    Marital_Status: string;
    Identity: string;
    Occupation: string;
    Occupation_Type: string;
    Education: string;
    Diet: string;
    diet_specifics: string;
    Exercise: string;
    Exercise_frequency: string;
    Exercise_duration: string;
    Routine: string;
    Sleep: string;
    sleep_specifics: string;
    sleep_quality: string;
    sleep_duration: string;
    Stress: string;
    stressors: string;
    stress_coping: string;
  };
}

export interface SocialHxInActiveProps {
  Bin: {
    Personal: {
      Marital_Status: string;
      Occupation_and_Education: string;
    };
    LifeStyle: {
      Dietary_Habits: string;
      Physical_Activity: string;
      Routine: string;
      Sleep_Routine: string;
      Stresses: string;
    };
  };
}

export interface MedsInActiveProps {
  Bin: {
    Trade_name: string;
    Salt: string;
    Number: string;
    Unit: string;
    Route: string;
    Frequency: string;
    Start_date: string;
    End_date: string;
  };
}

export interface MedsTradeProps {
  Trade: {
    Salt: string;
    Number: string;
    Unit: string;
    Route: string;
    Frequency: string;
    Start_date: string;
    End_date: string;
  };
}

export interface AllergiesInActiveProps {
  Bin: {
    Allergen: string;
    Allergen_Type: string;
    Reaction: string;
    Category: string;
    Start_Date: string;
    End_Date: string;
  };
}

export const binTitleMap: { [key: number]: string } = {
  1: "allergies",
  2: "drugs",
  3: "hopi",
  4: "meds",
  5: "obgyn",
  6: "screenings",
  7: "socialHx",
  8: "surgicalHx",
  9: "pastMedicalHx",
  10: "vaccine",
  11: "a_p",
  12: "familyHx",
};

export const FrequencyConversions: { [key: string]: number } = {
  "once a day": 1,
  "once daily": 1,
  "twice a day": 2,
  "twice daily": 2,
  "thrice a day": 3,
  "three times a day": 3,
  "three times daily": 3,
  "four times a day": 4,
  "five times a day": 5,
  "six times a day": 6,
  "seven times a day": 7,
  "eight times a day": 8,
  "every 4 hours": 6, // 24 hours divided by 4
  "every 5 hours": 4.8, // Approximation (24 / 5)
  "every 6 hours": 4, // 24 / 6
  "every 8 hours": 3, // 24 / 8
  "every 6-8 hours": 3.5, // Average of 6 and 8 (approx.)
  "every 4-6 hours": 5, // Average of 4 and 6
  "every 4-6 hrs": 5, // Same as above
  "every 4-6 hrs as needed": 5, // Same as above
  "4-6 hours as needed": 5, // Same as above
  "2-3 times daily": 2.5, // Average of 2 and 3
  "3-4 times daily": 3.5, // Average of 3 and 4
  "3-4 days": 0.75, // Assuming dosage is daily; average 3.5 days
  "3-6 days": 0.5, // Average of 3 and 6
  "daily in divided doses": 1, // Indicates once daily
  "three to four times a day": 3.5, // Average of 3 and 4
  "every 6-8 hrs if needed": 3.5, // Average of 6 and 8
  "every four hours": 6, // Same as "every 4 hours"
  "every six hours": 4, // Same as "every 6 hours"
  "up to 600 mg four times a day": 4, // Focus on frequency
  "200-400 mg three times a day": 3, // Focus on frequency
  "3-4 times a day": 3.5, // Average of 3 and 4
  "up to 4 times a day": 4, // Maximum of 4 times a day
  "3 times a day": 3, // Same as "three times a day"
};

//=============================================MEDS SHEET INFO==============================

//=============================================MEDS SHEET INFO==============================

//=========================================================================================

export const getDummyData = (bin: string) => {
  const emptyBins: any = {
    socialHx: {
      Marital_Status: "Married", //key 0 = marital [HEADING]
      Identity: "Hetrosexual", // key 1 = identity [HEADING]
      Occupation: "", // key 2 = occupation [HEADING]
      Occupation_Type: "part time", // key 3 = occ type
      Education: "", // key 4 = education [HEADING]
      Diet_Pattern: "Binge and Purge", // key 5 = diet pattern
      Set_Meal_Schedule: "Yes", // key 6 = meal schedule [HEADING]
      Cups_Fruit: "6-8 cups", // key 7 = cups fruit
      Cups_Veggies: "1-2 cups", // key 8 = cups veggies
      Helpings_Packaged: "2-4 helpings", // key 9 = helpings
      Specific_Consumptions: "Banaspati ghee", // key 10 = specific consumtion
      Sugar_Spoons: "04 spoons", // key 11 = sugar
      Articial_Sweetener: "2-3 times a week", // key 12 = sweetener
      Exercise: [
        // key 13 = exercise (array)
        {
          Exercise_Name: "Jogging",
          Exercise_Frequency: "2",
          Exercise_Duration: "12 minutes",
        },
        {
          Exercise_Name: " walking",
          Exercise_Frequency: "6",
          Exercise_Duration: "60 minutes",
        },
      ],
      Routine: "Varies", //key 14 = routine [HEADING]
      Sleep_Quality: "poor sleep every night", // key 15 = quality
      Sleep_Duration: "3 hours", // key 16 = duration
      Sleep_Same_Time: "Yes", // key 17 is sleep time [HEADING]
      Insomnia_Symptoms: "", //key 18 is insomnia
      Stress_Level: "", // key 19 stress level [HEADING]
      Stress_Symptoms: "", //key 20 symptoms
      Stress_Management: "", //key 21 stress mgmt
      Other: "i like working UI",
    },
    socialHxModel: {
      Marital_Status: "Widowed",
      Identity: "Other",
      Set_Meal_Schedule: "Yes",
      Diet_Pattern: "Other",
      cups_fruit: "None",
      cups_veggies: "1-2 cups",
      helpings_packaged: "None",
      specific_consumptions: "Banaspati ghee",
      sugar_spoons: "",
      Articial_Sweetener: "Never",
      Routine: "Other",
      Stress_Level: "Minimal Stress",
      Occupation: "I teach from 8 to 9.",
      Occupation_Type: "Other",
      Education: "Ph.D",
      Exercise: [
        {
          Exercise_Name: "No Exercise",
          Exercise_Frequency: "4",
          Exercise_Duration: "45-60 minutes",
        },
      ],
      Sleep_Quality: "Good sleep at least 3 nights in a week",
      Sleep_Duration: "8+ hours",
      Sleep_Same_Time: "Yes",
      Insomnia_Symptoms: "None",
      Stress_Symptoms: "Headaches",
      Stress_Management: "Other",
      Other: ["I'm very happy."],
    },
    meds: [
      //from model
      {
        Trade_Name: "Panadol",
        Salt: "pulmicort",
        Number: "500",
        Unit: "mg",
        Route: "oral",
        Frequency: "every 4 to 6 hours",
        Start_date: "2021-10-10",
        End_date: "2021-10-20",
        Deleted: "false",
      },
      {
        Trade_Name: "aspirin",
        Salt: "",
        Number: "2",
        Unit: "",
        Route: "po",
        Frequency: "prn",
        Start_date: "",
        End_date: "",
        Deleted: "false",
      },
      {
        Trade_Name: "ibuprofen",
        Salt: "amoxil4",
        Number: "20",
        Unit: "mg",
        Route: "iM",
        Frequency: "qid",
      },
      {
        Trade_Name: "Azomax",
        Salt: "amoxil6",
        Number: "50",
        Unit: "mg",
      },
    ],
    familyHx: [
      {
        Relation: "Father",
        Status: "Deceased",
        Age_at_death: "47",
        Medical_condition: [
          { Name: "Diabeties", Age: "34" },
          { Name: "Hypertensive Disorder", Age: "" },
        ],
        Additional_notes: "",
      },
      {
        Relation: "Mother",
        Status: "Alive",
        Age_at_death: "",
        Medical_condition: [{ Name: "Cancer", Age: "45" }],
        Additional_notes: "Pt is not behaving fine",
      },
    ],
    allergies: [
      {
        Allergen: "Rice",
        Reaction: "Causes swelling of hands",
        category: "Intolerance",
        Start_date: "2021-10-10",
        End_date: "2021-10-20",
      },
      {
        Allergen: "Black Chickpease",
        Reaction: "causes indigestion",
        category: "",
        Start_date: "2021-10-10",
        End_date: "2021-10-20",
      },
      {
        Allergen: "Pollen",
        Reaction: "causes skin hives",
        category: "Allergy",
        Start_date: "2021-10-10",
        End_date: "2021-10-20",
      },
      {
        Allergen: "Perfume",
        Reaction: "causes dizziness",
        category: "Sensitivity",
        Start_date: "2021-10-10",
        End_date: "2021-10-20",
      },
    ],
    pastMedicalHx: [
      {
        Disease: "Diabeties",
        Specification: "Type 2",
        Onset: "2021-10-10",
        Positive_Tags: ["Neuropathy", "Diet Control", "Vascular Complications"],
        Negative_Tags: ["Retinopathy", "Nephropathy"],
        Neutral_Tags: ["Since 1986", "2 Foot Amputations"],
        Tag_Keywords: {
          Neuropathy: ["Numbness", "Tingling", "Pain"],
          Diet_Control: [
            "Carb Counting",
            "Portion Control",
            "Regular Exercise",
          ],
          Vascular_Complications: [
            "Hypertension",
            "Hyperlipidemia",
            "Coronary Artery Disease",
          ],
        },
        Organs_Affected: [],
      },
      {
        Disease: "Hypertension",
        Specification: "Stage 2",
        Onset: "2021-10-10",
        Positive_Tags: ["Headaches", "Dizziness", "Fatigue"],
        Negative_Tags: ["Chest Pain", "Shortness of Breath"],
        Neutral_Tags: ["Since 2010", "Regular Medication"],
        Tag_Keywords: {
          Headaches: ["Frontal", "Occipital", "Temporal"],
          Dizziness: ["Vertigo", "Lightheadedness"],
          Fatigue: ["Generalized", "Postural"],
        },
        Organs_Affected: [],
      },
      {
        Disease: "Asthma",
        Specification: "Mild Persistent",
        Onset: "2021-10-10",
        Positive_Tags: ["Wheezing", "Cough", "Shortness of Breath"],
        Negative_Tags: ["Chest Pain", "Hemoptysis"],
        Neutral_Tags: ["Since 2000", "Regular Medication"],
        Tag_Keywords: {
          Wheezing: ["Day", "Night"],
          Cough: ["Dry", "Productive"],
          Shortness_of_Breath: ["Exertional", "At Rest"],
        },
        Organs_Affected: [],
      },
      {
        Disease: "GERD",
        Specification: "Severe",
        Onset: "2021-10-10",
        Positive_Tags: ["Heartburn", "Regurgitation", "Dysphagia"],
        Negative_Tags: ["Chest Pain", "Hematemesis"],
        Neutral_Tags: ["Since 2005", "Regular Medication"],
        Tag_Keywords: {
          Heartburn: ["Postprandial", "Nocturnal"],
          Regurgitation: ["Food", "Bile"],
          Dysphagia: ["Solids", "Liquids"],
        },
        Organs_Affected: [],
      },
    ],
    // Add more bins and their fields
  };

  return emptyBins[bin] || {};
};

export const getEmptyBins = (bin: string) => {
  const emptyBins: any = {
    socialHx: {
      Personal: {
        Marital_Status: "",
        Identity: "",
        Occupation: "",
        Education: "",
      },
      LifeStyle: {
        Dietary_Habits: "",
        Exercise: "",
        Routine: "",
        Sleep: "",
        Stress: "",
      },
    },
    meds: {
      Trade_name: "",
      Salt: "",
      Number: "",
      Unit: "",
      Route: "",
      Frequency: "",
      Start_date: "",
      End_date: "",
    },
    // Add more bins and their fields
  };

  return emptyBins[bin] || {};
};

export const getFieldOptions = (bin: string, field: string) => {
  // console.log("getFieldOptions called for: ", field);
  field = field.replace(/ /g, "_");
  const options: any = {
    socialHx: {
      Marital_Status: [
        "Single",
        "Married",
        "Divorced or Separated",
        "Widowed",
        "Other",
      ],
      Identity: ["Heterosexual", "Homosexual", "Other"],
      Exercise_Name: [
        "Cycling",
        "Walking",
        "Running or Jogging",
        "Yoga or Plates",
        "Strength Training",
        "No Exercise",
        "Other",
      ],
      Routine: [
        "Remains the same most days",
        "Changes once a week",
        "Changes every 2-3 days",
        "Changes every day",
        "Other",
      ],
      Stress_Level: [
        "Minimal Stress",
        "Some stress but coping well",
        "Stressed but able to cope",
        "Moderate Stress, difficult to manage",
        "Very stressed, overwhelmed",
      ],
      Occupation: [
        "Employed",
        "Unemployed",
        "Student",
        "Retired",
        "Home Maker",
        "Other",
      ],
      Occupation_Type: [
        "Full-time",
        "Part-time",
        "Freelance/Contract",
        "Other",
      ],
      Education: [
        "Under-Matric",
        "Matric/O level",
        "FSC/A level",
        "Bachelors",
        "Post-Graduation",
      ],
      Exercise_Frequency: ["1", "2", "3", "4", "5", "6", "7"],
      Exercise_Duration: [
        "0-15 minutes",
        "15-30 minutes",
        "30-45 minutes",
        "45-60 minutes",
        ">60 minutes",
      ],
      Sleep_Quality: [
        "Poor sleep every night",
        "Poor sleep at least 3 nights in a week",
        "Good sleep on some nights, poor on others",
        "Good sleep at least 3 nights in a week",
        "Good sleep every night",
        "Other",
      ],
      Sleep_Duration: ["Less than 4", "4-6 hours", "6-8 hours", "8+ hours"],
      Sleep_Same_Time: ["Yes", "No"],
      Insomnia_Symptoms: [
        "None",
        "Difficulty falling asleep",
        "Waking up during the night and having trouble falling back asleep",
        "Waking up too early and being unable to fall back asleep",
      ],
      Stress_Symptoms: [
        "No Symptoms",
        "Select All",
        "Medical condition",
        "Headaches",
        "Indigestion",
        "Insomnia",
        "Fatigue",
        "Muscle Cramps",
        "None",
        "Other",
      ],
      Stress_Management: [
        "No daily practice",
        "Select All",
        "Meditation",
        "Talking to a friend",
        "Making time for hobbies",
        "Breathing Techniques",
        "Therapy",
        "None",
        "Other",
      ],
      Set_Meal_Schedule: ["Yes", "No"],
      Diet_Pattern: [
        "No patterns",
        "Select All",
        "Binge eating",
        "Not eat for long periods of time",
        "Binge and purge",
        "Other",
      ],
      cups_fruit: [
        "None",
        "1-2 cups",
        "2-4 cups",
        "4-6 cups",
        "6-8 cups",
        "More than 8 cups",
      ],
      cups_veggies: [
        "None",
        "1-2 cups",
        "2-4 cups",
        "4-6 cups",
        "6-8 cups",
        "More than 8 cups",
      ],
      helpings_packaged: [
        "None",
        "1-2 helpings",
        "2-4 helpings",
        "4-6 helpings",
        "More than 8 helpings",
      ],
      specific_consumptions: [
        "No Specific Consumptions",
        "Select All",
        "Banaspati ghee",
        "Everyday tea whitener",
        "Margarine",
      ],
      sugar_spoons: [
        "None",
        "01 spoon",
        "02 spoons",
        "03 spoons",
        "04 spoons",
        "05 spoons",
        "06 spoons",
        "More than 6 spoons",
      ],
      Articial_Sweetener: [
        "Never",
        "1-2 times a week",
        "2-3 times a week",
        "1-2 times a month",
        "Once in 6 months",
        "Once a year",
      ],
    },
    meds: {
      //to be filled by abdullah
      Trade_name: [
        "Aspirin",
        "Ibuprofen",
        "Acetaminophen",
        "Metformin",
        "Lisinopril",
      ],
      Salt: [
        "Sodium Chloride",
        "Potassium Chloride",
        "Calcium Carbonate",
        "other",
      ],
      Number: ["1", "2", "3", "4", "other"],
      Unit: ["mg", "g", "ml", "other"],
      Route: [
        "Oral",
        "Intravenous",
        "Intramuscular",
        "Subcutaneous",
        "Topical",
        "Inhalation",
        "other",
      ],
      Frequency: [
        "Once daily",
        "Twice daily",
        "Three times daily",
        "Every 4 hours",
        "Every 6 hours",
        "Every 8 hours",
        "Every 12 hours",
        "other",
      ],
      Start_date: ["YYYY-MM-DD", "Today", "other"],
      End_date: ["YYYY-MM-DD", "Ongoing", "other"],
    },
    familyHx: {
      Relation: [
        "Mother",
        "Father",
        "Sister",
        "Brother",
        "Half Sister",
        "Half Brother",
        "Daughter",
        "Son",
        "Guardian",
        "Maternal Grandmother",
        "Maternal Grandfather",
        "Paternal Grandmother",
        "Paternal Grandfather",
        "Maternal Aunt",
        "Maternal Uncle",
        "Paternal Aunt",
        "Paternal Uncle",
        "Maternal Cousin",
        "Paternal Cousin",
        "Niece",
        "Nephew",
        "Partner/Spouse",
        "Lives in the same household",
        "Other",
      ],
      Relation_adopted: [
        "Biological Mother",
        "Adoptive Mother",
        "Biological Father",
        "Adoptive Father",
        "Biological Sister",
        "Adoptive Sister",
        "Sister",
        "Biological Brother",
        "Adoptive Brother",
        "Brother",
        "Half Sister",
        "Half Brother",
        "Daughter",
        "Son",
        "Guardian",
        "Maternal Grandmother",
        "Maternal Grandfather",
        "Paternal Grandmother",
        "Paternal Grandfather",
        "Maternal Aunt",
        "Maternal Uncle",
        "Paternal Aunt",
        "Paternal Uncle",
        "Maternal Cousin",
        "Paternal Cousin",
        "Niece",
        "Nephew",
        "Partner/Spouse",
        "Lives in the same household",
        "Other",
      ],
      Status: [
        "Alive",
        "Deceased",
        "No longer in contact",
        "Declined to specify",
        "Other",
      ],
      Medical_condition: [
        "Diabetes Type 1",
        "Diabetes Type 2",
        "High Cholesterol",
        "Coronary Artery Disease",
        "Asthma",
        "Thalassemia",
        "PCOS (Polycystic Ovary Syndrome)",
        "Chronic Kidney Disease",
        "Chronic Liver Disease",
        "Hyperthyroidism",
        "Hypertension",
        "Tuberculosis",
        "Ischemic Stroke",
        "Osteoarthritis",
        "Anemia",
        "Hepatitis B",
        "Hepatitis C",
        "Other",
      ],
    },
    allergies: {
      Food_Allergens: [
        "Milk",
        "Eggs",
        "Peanuts",
        "Tree nuts (eg. almonds, walnuts, cashews, pistachios, hazelnuts)",
        "Fish",
        "Shellfish (eg. shrimp, crab, lobster)",
        "Soy",
        "Wheat",
        "Gluten",
        "Sesame",
        "Soybean",
        "Mustard",
        "Artificial Food Coloring",
        "Sulfites (found in dried fruits)",
        "Gelatin",
        "Legumes (eg. lentils, peas)",
      ],
      Environmental_Allergens: [
        "Pollen",
        "Mold",
        "Dust mites",
        "Animal dander",
        "Insect bites",
        "Plant contact (eg. Poison ivy, Poison oak, Poison sumac)",
      ],
      Chemical_Allergens: [
        "Detergents",
        "Bleach",
        "Ammonia",
        "Synthetic fragrances",
        "Essential oils",
        "Cosmetics",
        "Latex",
        "Industrial chemicals (eg. Nickel, Chromium, Cobalt)",
      ],
      Drug_Class: [
        "Antibiotics",
        "NSAIDs",
        "Anticonvulsants",
        "Antidiabetics",
        "Local anesthetics",
        "Anticoagulants",
        "Antivirals",
        "Corticosteroids",
        "Antipsychotics",
      ],
      Category: ["Allergy", "Intolerance", "Adverse drug reaction"],
      Allergen_Type: [
        "Food",
        "Environmental",
        "Chemical",
        "Drug Formulation",
        "Drug Ingredient",
        "Drug Class",
      ],
      Reaction: [
        "Anaphylaxis",
        "Anxiety",
        "Atopic Dermatitis",
        "Breathing difficulties",
        "Contact Dermatitis",
        "Cough",
        "Diarrhea",
        "Dizziness",
        "Drowsiness",
        "Itching",
        "Seizures",
        "Swelling",
        "Photosensitivity",
        "Hives",
        "Other",
      ],
      Start_Date: ["YYYY-MM-DD", "Today", "other"],
      End_Date: ["YYYY-MM-DD", "Ongoing", "other"],
      Carry_Epipen: ["Yes", "No"],
      Used_Epipen: ["Yes", "No"],
      Last_Used_Epipen: ["YYYY-MM-DD", "Never", "other"],
    },
    pastMedicalHx: {
      Disease: [
        "Diabetes Type 1",
        "Diabetes Type 2",
        "Asthma",
        "High Cholesterol",
        "Coronary Artery Disease",
        "Thalassemia",
        "Hyperthyroidism",
        "Anemia",
        "PCOS (Polycystic Ovary Syndrome)",
        "Chronic Kidney Disease",
        "Chronic Liver Disease",
        "Hypertension",
        "Tuberculosis",
        "Ischemic Stroke",
        "Osteoarthritis",
        "Hepatitis B",
        "Hepatitis C",
      ],
      TagColors: ["Red", "Green", "Blue", "Gray"],
    },
    // Add more bins and their fields
  };

  return options[bin]?.[field] || [];
};
