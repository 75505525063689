/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useCallback, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Box from "@mui/material/Box";
import {
  IconButton,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Collapse } from "@mui/material";
import { removeNils } from "shared-utils";
import {
  Visit,
  useAllHealthOrgsQuery,
  useLazyClinicsInHealthOrgQuery,
  useLazyRecordingsByPrefixIdQuery,
  useLazyVisitsQuery,
} from "../../graphql/generated";
import AmchurCard from "../../components/AmchurCard";
import TranscriptionPanel from "../visitInProgress/TranscriptionPanel";
import {
  Packet,
  TranscriptionSection,
} from "../../hooks/useTranscriptionState";
import { BinSet } from "../../hooks/useAudioProcessing";
import BinCardStack from "../../components/BinCardStack";
import serviceBag, { useServiceBag } from "../../services/ServiceBag";
import SehatSpinner from "../../components/SehatSpinner";
import { zipDownloadsFrom } from "../../domain/recordingDomain";
import { fileService } from "../../services/fileService";

interface ClinicMap {
  [key: string]: string;
}
function ReviewVisitsRoute() {
  const { data: healthOrgs } = useAllHealthOrgsQuery();
  const [loadClinicsInHealthOrg] = useLazyClinicsInHealthOrgQuery();
  const [loadVisitsForClinic] = useLazyVisitsQuery();

  const [recordingsByPrefixId] = useLazyRecordingsByPrefixIdQuery();
  const [visits, setVisits] = useState<Partial<Visit>[]>([]);
  const [clinicNameMap, setClinicNameMap] = useState<ClinicMap>({});

  useEffect(() => {
    async function loadOneSetOfVisits(clinicId: string) {
      console.log(`query for clinic ${clinicId}`);
      const { data: visits } = await loadVisitsForClinic({
        criteria: { clinicId },
      });
      console.log(`and we received ${JSON.stringify(visits?.visits)}`);
      return visits?.visits || [];
    }

    async function loadOneSetOfClinics(orgId: string) {
      const { data: clinics } = await loadClinicsInHealthOrg({ orgId });
      return clinics?.clinicsInHealthOrg || [];
    }
    const healthOrgIds =
      removeNils(healthOrgs?.allHealthOrgs?.map((org) => org?.orgId)) || [];

    async function loadAllClinics() {
      const promises = healthOrgIds.map((orgId) => loadOneSetOfClinics(orgId));
      const clincArrays = await Promise.all(promises);
      const clinics = clincArrays.flat();
      // making clinicIds array and an id to name map of clinics
      const clinicMap: ClinicMap = {};
      const clinicIds = removeNils(clinics).map((clinic) => {
        clinicMap[clinic.clinicId] = clinic?.name || "";
        return clinic?.clinicId;
      });
      setClinicNameMap(clinicMap);
      return clinicIds;
    }

    async function loadAllVisits(clinicIds: string[]) {
      const promises = clinicIds.map((clinicId) =>
        loadOneSetOfVisits(clinicId)
      );
      console.log(`wait for visit arrays`);
      const visitArrays = await Promise.all(promises);
      console.log(`we got visit arrays ${JSON.stringify(visitArrays)}`);
      return visitArrays.flat();
    }
    async function loadAll() {
      const clinicIds = await loadAllClinics();
      const visits = await loadAllVisits(removeNils(clinicIds));
      setVisits(removeNils(visits));
      console.log(`we got visits ${JSON.stringify(visits)}`);
    }

    void loadAll();
  }, [healthOrgs, loadClinicsInHealthOrg, loadVisitsForClinic]);

  function downloadRecording(archiveId: string) {
    recordingsByPrefixId({ id: archiveId })
      .then(async (response) => {
        const urls = removeNils(
          response?.data?.recordingsByPrefixId.map((res) => res?.downloadUrl)
        );
        const ids = Array.from(
          { length: urls.length },
          (_, index) => `${index + 1}`
        );
        const result = await zipDownloadsFrom(
          ids,
          urls,
          serviceBag.fileService
        );
        fileService.downloadData(result, "recording.zip");
      })
      .catch((error) => console.log("error", error));
  }
  if (visits.length === 0) {
    return <SehatSpinner />;
  }
  return (
    <ReviewVisitsStateView
      visits={visits}
      clinicNameMap={clinicNameMap}
      downloadRecording={downloadRecording}
    />
  );
}

interface ReviewVisitsStateViewProps {
  visits: Partial<Visit>[];
  clinicNameMap: ClinicMap;
  downloadRecording: (archiveId: string) => void;
}

function ReviewVisitsStateView({
  visits,
  clinicNameMap,
  downloadRecording,
}: ReviewVisitsStateViewProps) {
  const [filteredVisits, setFilteredVisits] = useState<Partial<Visit>[]>([]);
  const [dateRange, setDateRange] = useState<calendarState[]>([
    {
      startDate: undefined,
      endDate: undefined,
      key: "selection",
    },
  ]);
  const [toggleCalendar, setToggleCalendar] = useState(false);
  const [selectedButton, setSelectedButton] = useState("");

  const { envService } = useServiceBag();
  const now = envService.currentTime();

  // when component renders, filteredVists will be same as fetched visits
  useEffect(() => {
    setFilteredVisits(visits);
  }, [visits]);

  const filterVisits = useCallback(
    (exactMatch: boolean, startDate: DateTime, endDate?: DateTime) => {
      const currDate = now;
      const tempVisits = visits.filter((visit) => {
        if (exactMatch) {
          if (endDate) {
            setSelectedButton("CD");
            const targetDate = DateTime.fromISO(visit.createdAt).startOf("day");
            return (
              startDate.startOf("day") <= targetDate &&
              targetDate <= endDate.startOf("day")
            );
          }
          return DateTime.fromISO(visit.createdAt).hasSame(startDate, "day");
        } else {
          // if startDate is a week old, then it'll return all visits which have
          // date > than last week's date and less than today's date (essentionaly
          // all the visits in past week)
          const visitDate = DateTime.fromISO(visit.createdAt);
          return startDate <= visitDate && visitDate < currDate;
        }
      });
      setFilteredVisits(tempVisits);
    },
    [visits, now]
  );
  const filterDate = useCallback(
    (day: string) => {
      let targetDate = now;
      let exactMatch = false;
      switch (day) {
        case "T":
          exactMatch = true;
          break;
        case "Y":
          exactMatch = true;
          targetDate = targetDate.minus({ days: 1 });
          break;
        case "7D":
          targetDate = targetDate.minus({ days: 7 });
          break;
        case "1M":
          targetDate = targetDate.minus({ months: 1 });
          break;
        case "1Y":
          targetDate = targetDate.minus({ years: 1 });
          break;
        case "N": // without any date filter
          targetDate = DateTime.fromISO("1970-01-01");
      }

      setSelectedButton(day);
      filterVisits(exactMatch, targetDate);
    },
    [filterVisits, now]
  );

  return (
    <ReviewVisitsUiView
      visits={filteredVisits}
      clinicNameMap={clinicNameMap}
      filterDate={filterDate}
      filterVisits={filterVisits}
      dateRange={dateRange}
      setDateRange={setDateRange}
      toggleCalendar={toggleCalendar}
      setToggleCalendar={setToggleCalendar}
      selectedButton={selectedButton}
      downloadRecording={downloadRecording}
    />
  );
}

interface calendarState {
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  key?: string;
}
interface ReviewVisitsUiViewProps {
  visits: Partial<Visit>[];
  clinicNameMap: ClinicMap;
  filterDate: (day: string) => void;
  filterVisits: (
    match: boolean,
    startDate: DateTime,
    endDate: DateTime
  ) => void;
  toggleCalendar: boolean;
  setToggleCalendar: (flag: boolean) => void;
  dateRange: calendarState[];
  setDateRange: (range: calendarState[]) => void;
  selectedButton: string;
  downloadRecording: (archiveId: string) => void;
}
function ReviewVisitsUiView({
  visits,
  clinicNameMap,
  filterDate,
  filterVisits,
  toggleCalendar,
  setToggleCalendar,
  dateRange,
  setDateRange,
  selectedButton,
  downloadRecording,
}: ReviewVisitsUiViewProps) {
  const filterButtons = [
    { name: "Today", key: "T" },
    { name: "Yesterday", key: "Y" },
    { name: "7D", key: "7D" },
    { name: "1M", key: "1M" },
    { name: "1Y", key: "1Y" },
    { name: "All", key: "N" },
  ];

  return (
    <Stack direction="column" mx={4} mt={0}>
      <Typography variant="navHeader" align="left">
        All Visits
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        my={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Stack direction="row" spacing={2} my={2}>
          <Button
            color={
              toggleCalendar
                ? "secondary"
                : selectedButton === "CD"
                ? "info"
                : "primary"
            }
            variant="contained"
            size="small"
            startIcon={<CalendarMonthIcon />}
            onClick={() => {
              if (toggleCalendar) {
                filterVisits(
                  true,
                  DateTime.fromJSDate(dateRange[0].startDate || new Date()),
                  DateTime.fromJSDate(dateRange[0].endDate || new Date())
                );
              }
              setToggleCalendar(!toggleCalendar);
              //  setting range back to default
              setDateRange([
                {
                  startDate: undefined,
                  endDate: undefined,
                  key: "selection",
                },
              ]);
            }}
          >
            {toggleCalendar ? "Apply" : "Custom"}
          </Button>
          {toggleCalendar && (
            <Box
              style={{
                position: "absolute",
                bottom: "4rem",
                zIndex: 1,
              }}
            >
              <DateRange
                editableDateInputs={true}
                startDatePlaceholder="start date"
                endDatePlaceholder="end date"
                onChange={(item) => {
                  setDateRange([item.selection]);
                }}
                moveRangeOnFirstSelection={false}
                ranges={dateRange}
                fixedHeight={true}
              />
            </Box>
          )}
          {filterButtons.map((button, key) => (
            <Button
              key={key}
              variant="contained"
              size="small"
              color={selectedButton === button.key ? "info" : "primary"}
              onClick={() => filterDate(button.key)}
            >
              {button.name}
            </Button>
          ))}
        </Stack>

        <Typography variant="h3">Total: {visits.length}</Typography>
      </Stack>

      <VisitsTable
        visits={visits}
        clinicNameMap={clinicNameMap}
        downloadRecording={downloadRecording}
      />
    </Stack>
  );
}

function ReviewOneVisitExpanded({ visit }: { visit?: Partial<Visit> }) {
  if (!visit) {
    return <div> Select a visit</div>;
  }

  // DRY this out  (occurs in useTranscription State)
  const packets = JSON.parse(visit.transcription || "[]") as Packet[];
  console.log(`packets are ${JSON.stringify(packets)}`);
  const packetArray = packets.map(
    (packet) => [packet.header, packet.content] as TranscriptionSection
  );
  //.filter((packet) => packet[1].length > 0);
  console.log(`packetArray are ${JSON.stringify(packetArray)}`);

  return (
    <Stack direction="column" my={3} sx={{ rowGap: 2 }}>
      <TranscriptionPanel
        transcription={packetArray}
        transcriptionHeight="150px"
        transcriptionListHeight="150px"
        transcriptionInProgress={false}
      />
      <AmchurCard title="Generated Notes" role="secondary">
        <Typography textAlign="left">{visit.originalNotes || ""}</Typography>
      </AmchurCard>
      <BinCardStack binSet={visit as BinSet} />
    </Stack>
  );
}

interface VisitTableProps {
  visits: Partial<Visit>[];
  clinicNameMap: ClinicMap;
  downloadRecording: (archiveId: string) => void;
}

function VisitsTable({
  visits,
  clinicNameMap,
  downloadRecording,
}: VisitTableProps) {
  const tableHeading = [
    "Token",
    "VisitId",
    "Name",
    "Email",
    "Clinic",
    "Date",
    "Time",
    "Recording",
  ];
  const colNum = tableHeading.length + 1; // +1 for dropdown symbol

  return (
    <TableContainer
      component={Paper}
      sx={{ border: 1, borderColor: "neutralBackground.main" }}
    >
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell key={-1} />
            {tableHeading.map((heading: string, key) => {
              return (
                <TableCell key={key} sx={{ fontWeight: "bold" }}>
                  {heading}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {visits.length === 0 ? (
            <TableRow>
              <TableCell colSpan={colNum}>
                <Typography align="center">No Visits</Typography>
              </TableCell>
            </TableRow>
          ) : (
            visits.map((visit, key) => {
              return (
                <Row
                  key={key}
                  visit={visit}
                  colNum={colNum}
                  clinicNameMap={clinicNameMap}
                  downloadRecording={downloadRecording}
                />
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface VisitTableRowProps {
  visit: Partial<Visit>;
  colNum: number;
  clinicNameMap: ClinicMap;
  downloadRecording: (archiveId: string) => void;
}

function Row({
  visit,
  colNum,
  clinicNameMap,
  downloadRecording,
}: VisitTableRowProps) {
  const [open, setOpen] = useState<boolean>(false);
  const visitDateTime = visit?.createdAt;
  const formattedDate = new Date(visitDateTime).toLocaleDateString();
  const formattedTime = new Date(visitDateTime).toLocaleTimeString();
  const fullName = removeNils([
    visit?.physician?.givenName,
    visit?.physician?.familyName,
  ]).join(" ");

  return (
    <>
      <TableRow style={{ cursor: "pointer" }}>
        <TableCell size="small">
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell size="small">{visit?.patientId || ""}</TableCell>
        <TableCell size="small">{visit?.visitId || ""}</TableCell>
        <TableCell size="small">{fullName}</TableCell>
        <TableCell size="small">{visit.physician?.email || ""}</TableCell>
        <TableCell size="small">
          {clinicNameMap[visit?.clinicId || ""] || ""}
        </TableCell>
        <TableCell size="small">{formattedDate || ""}</TableCell>
        <TableCell size="small">{formattedTime || ""}</TableCell>
        <TableCell size="small">
          <Button onClick={() => downloadRecording(visit?.visitId || "")}>
            Download
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colNum}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ReviewOneVisitExpanded visit={visit} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default ReviewVisitsRoute;
