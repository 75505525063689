import React, { useEffect, useState } from "react";
import {
  TextField,
  Autocomplete,
  InputAdornment,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface SearchFieldProps {
  options: string[];
  label: string;
  onChange: (value: string) => void;
  value: string;
  required?: boolean;
}

const SearchField: React.FC<SearchFieldProps> = ({
  options,
  label,
  value,
  onChange,
  required = false,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (value !== inputValue) {
      if (options.includes(value)) {
        setInputValue(value);
      } else {
        setInputValue("Other");
      }
    }
  }, [value, options, inputValue]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        width: "100%",
      }}
    >
      <Typography
        variant="body2"
        fontSize="clamp(12px, 0.8vw, 16px)"
        color="text.secondary"
        textAlign={"left"}
        sx={{ marginLeft: "4px" }}
      >
        {label}
        {required && <span style={{ color: "red", marginLeft: "4px" }}>*</span>}
      </Typography>
      <Autocomplete
        freeSolo
        value={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
          onChange(newInputValue);
        }}
        options={options.filter((option) =>
          option.toLowerCase().includes(inputValue.toLowerCase())
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            {...rest}
            InputProps={{
              ...params.InputProps,
              sx: {
                height: "4vh",
                border: "1px solid #AEBECD",
                backgroundColor: "white",
                fontSize: "clamp(15px, 0.8vw, 24px)",
                borderRadius: "8px",
                "&:focus": {
                  backgroundColor: "transparent",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:focus .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              },
              startAdornment:
                inputValue === "" ? (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#AEBECD" }} />
                  </InputAdornment>
                ) : null,
            }}
          />
        )}
      />
    </div>
  );
};

export default SearchField;
