/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as R from "ramda";
import { Maybe } from "graphql/jsutils/Maybe";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (...args: any[]) => any;

/* eslint-disable @typescript-eslint/no-unsafe-argument */
export const withUnusedLeadingArgs = (func: AnyFunction, ignore = 1) =>
  R.compose(R.apply(func), R.unapply(R.drop(ignore)));

export const debugChain = (func: AnyFunction) => {
  const debug = () => {
    debugger;
  };
  return R.compose(R.tap(debug), func);
};

export const logChain = (str: string, func: AnyFunction) => {
  const logit = R.curry(R.binary(console.log))(str);
  return R.compose(R.tap(logit), func);
};

//export function apply<F extends AnyFunction>(fn: F): (args: Parameters<F>) => ReturnType<F>;

// creates a zero argument (0-ary, or null-ary) function that calls the
// given function with the given arguments.
// Useful for creating event handlers
export const nullary = <F extends AnyFunction>(
  func: F,
  ...args: Parameters<F>
): (() => ReturnType<F>) => R.compose(R.apply(func), R.always(args));

// takes a function that takes a boolean, and creates functions
// that call it with either boolean state.
export const nullaryBool = <T>(func: (b: boolean) => T): (() => T)[] => [
  nullary(func, true),
  nullary(func, false),
];

const removeNilsFunc = R.unless(
  R.isNil,
  //@ts-ignore
  R.filter<unknown | null | undefined>(R.isNotNil)
);

// this extra wrapper gives TS safety at the const of another function call.
export function removeNils<T>(r: Maybe<Maybe<T>[]>): T[] {
  return removeNilsFunc(r) as T[];
}

type KeysType = {
  [id: string]: string[];
};

function unflattenObjFunc(keys: KeysType, obj: unknown) {
  return Object.keys(keys).reduce((acc, k) => {
    //@ts-ignore
    acc[k] = R.pick(keys[k], obj);
    return acc;
  }, {});
}

export const unflattenObj = R.curry(unflattenObjFunc);

// creates a function that splits a string with • separator, and turns
// it into an object by mapping the strings to the names in the field array.
export function splitIxStringInfoFunc<T>(fields: string[]) {
  return R.compose(R.zipObj(fields), R.split("•")) as (s: string) => T;
}

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
