import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import GreenTick from "./../../../../assets/GreenTick.svg";

interface AlertProps {
  msg: string;
  setShowAlert: (value: boolean) => void;
  type: "success" | "error";
}

const Alert: React.FC<AlertProps> = ({ msg, setShowAlert, type }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [setShowAlert]);

  const isSuccess = type === "success";
  const alertIcon = GreenTick;
  const alertColor = isSuccess ? "#34BC78" : "#FF4040"; // Green for success, red for error

  return (
    <div
      style={{
        width: "100%",
        border: `2px solid ${alertColor}`,
        borderRadius: "8px",
        backgroundColor: "white",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ padding: "10px" }}
      >
        <img
          src={alertIcon}
          alt={isSuccess ? "Green Tick" : "Red Cross"}
          style={{ width: "20px", height: "20px" }}
        />
        <Typography
          sx={{ color: "black", textAlign: "center", padding: "1px" }}
        >
          {msg}
        </Typography>
      </Stack>
    </div>
  );
};

export default Alert;
