/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
import * as R from "ramda";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCreatePatientMutation } from "../../hooks/apiHooks/patientMutationHooks";
import { PatientCreation } from "../../graphql/generated";
import { RootState } from "../../state/reducers";
import useStartVisit from "../../hooks/useStartVisit";
import useCurrentClinicId from "../../hooks/useCurrentClinic";
import { trackEvent } from "../../app/eventTracker";
import CreatePatientStateView from "./CreatePatientStateView";

const patientKeysOnly = R.pick(["patientId", "patientIdType"]);

function CreatePatientRoute() {
  const navigate = useNavigate();
  const [createPatientOnServer] = useCreatePatientMutation();
  const patientId = useSelector(
    (state: RootState) => state.currentPatient.patientId
  );

  const clinicId = useCurrentClinicId();
  const { startTheVisit } = useStartVisit();

  const createPatient = (
    patientId: string,
    password: string,
    birthday: Date | null,
    firstName: string,
    lastName: string,
    allergies: string[]
  ) => {
    const args: PatientCreation = {
      patientId,
      patientIdType: "PKCNI",
      password,
      birthday,
      firstName,
      lastName,
      allergies,
    };
    return createPatientOnServer({ patient: args }).then(() => {
      trackEvent("FePatientCreated"); // tracking patient creation on mixpanel
      startTheVisit(clinicId, patientKeysOnly(args));
    });
  };

  const onCancel = () => {
    navigate("/greeter-dashboard");
  };
  return (
    <CreatePatientStateView
      createPatient={createPatient}
      onCancel={onCancel}
      initialId={patientId || ""}
    />
  );
}

export default CreatePatientRoute;
