import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AllergiesState {
  allergenNames: string[];
}

const initialState: AllergiesState = {
  allergenNames: [],
};

const AllergiesBinSlice = createSlice({
  name: "allergies",
  initialState,
  reducers: {
    setAllergenNames(state, action: PayloadAction<string[]>) {
      state.allergenNames = action.payload;
    },
  },
});

export const { setAllergenNames } = AllergiesBinSlice.actions;

export default AllergiesBinSlice.reducer;
